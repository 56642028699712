import { Component, OnInit } from '@angular/core';
import { HasInitializedCtor } from '@angular/material/core';

import {
  faEnvelope,
  faImages,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {

  mailIcon = faEnvelope;
  imgsIcon = faImages;

  constructor() { }

  ngOnInit(): void {
    let list = document.querySelectorAll(".mobile")


    for (let idx = 0; idx < list.length; idx++) {
      let l = list[idx] as HTMLElement
      l.onclick = () => this.setbehavior(l)
    }
  }


  setbehavior(l: HTMLElement) {
    (document.querySelector(".about-container") as HTMLDivElement).style.overflow = "hidden"
    l.style.position = "absolute"
    l.style.top = "0"
    l.style.left = "0"
    l.style.width = "100vw"
    l.style.height = "100vh"
    l.style.zIndex = "100"
    l.style.transform = "rotate(0deg) scale(1) translateY(0)"
    setTimeout(() => { l.style.position = "fixed" })
    l.onclick = () => {
      let parent = (document.querySelector(".about-container") as HTMLDivElement)
      parent.removeAttribute("style")
      l.removeAttribute("style")
      l.onclick = () => this.setbehavior(l)
    }
  }


  inquiries(): void {
    window.location.href = "mailto:hello@lunabae.love?subject=Business%20Inquiry";
  }

}
