<!-- <img src="../../assets/LunaBae.svg" id="luna_logo" alt="LUNA PNG"> -->

<div class="home-background">
</div>

<div class="container">
  <!-- <img src="../../assets/LunaBae.svg" id="luna_logo" alt="LUNA PNG"> -->
  <!-- <img src="../../assets/logo_white_transparentbck.png" id="luna_logo" alt="LUNA PNG"> -->



  <button class="lunabae-btn" (click)="Link('about')" style="margin-top: 25vh;">
    ABOUT ME
  </button>


  <button class="lunabae-btn" (click)="Link('gallery')">
    PORTFOLIO
  </button>

  <button id="nfts" class="lunabae-btn" (click)="externalLink('https://opensea.io/collection/rosado-planet')">
    SHOP NFTS
  </button>

  <button class="lunabae-btn" (click)="collabAction()">
    BOOK NOW
  </button>
</div>

<social-networks color="white" style="position: fixed; width: 100vw; bottom: 0;"></social-networks>
