<div class="about-container">
    <div class="controls">
        <!-- <fa-icon [icon]="mailIcon" (click)="inquiries()" size="2x" class="icon"></fa-icon> -->
        <!-- <fa-icon [icon]="imgsIcon" [routerLink]="['/gallery']" size="2x" class="icon"></fa-icon> -->
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/tennis.jpg);">
        </div>
        <img src="../../assets/tennis.jpg" width="500px" height="330px" alt="" class="mobile">
        <!-- <img src="../../assets/tenniss.png" width="100%" height="100%" alt="" class="mobile"> -->
        <h3>
            <!-- LunaBae is an AfroLatina, Actress, Influencer, and Model known for sharing innovative beauty, -->
            <!-- fashion, and travel content across her various platforms. -->
        Luna Bae is a dynamic Afro-Latina model, actress, and influencer, proudly representing her Cuban heritage.
        Born and raised in Bayamo, Cuba, she brings the vibrancy of her roots to the global stage, seamlessly blending high fashion,
        beauty, and digital media. Now based in Louisville, KY, Luna is making waves in the industry with her striking presence,
        fearless authenticity, and undeniable influence.
        </h3>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/Haydee63.jpg);">
        </div>
        <img src="../../assets/Haydee63s.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            <!-- Born and raised in Bayamo, Cuba, she moved to the US at an early age to continue chasing her dreams. Early in 2019, she was discovered by a Louisville photographer and earned an opportunity to walk in NYFW later that year. -->

        Luna has graced some of the most prestigious runways, including NYFW, CFW, and DFW, as well as the Macy’s Annual Fashion Show. Her work with fashion brands such as AIF, Feline Apparel, and Macy’s has further established her as a sought-after model. Expanding her artistry beyond the runway, Luna has also made an impact in film, with roles in Brewster’s Millions Christmas and Dance For Me, both featured on BET+. She has also starred in the music video "Bombay", which has garnered 2.2 million views on YouTube and was featured on VH1.
        </h3>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/lunabae_classy.jpg);">
        </div>
        <img src="../../assets/lunabae_classys.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            <!-- She is the first Cuban-Jamaican model to be featured in Louisville's Courier Journal and Today's Woman Magazine publications as well as moments on television on WHAS News. -->
        Beyond fashion and entertainment, Luna has become a powerful presence in the beauty industry, collaborating with top skincare brands such as Good Molecules, Bambú Earth, and The Inkey List. Her ability to connect with audiences through authentic content has made her a trusted influencer in the beauty and wellness space.
        </h3>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/lunabae_colorful.jpg);"></div>
        <img src="../../assets/lunabae_colorfuls.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            <!-- She has been featured in multiple music videos across major networks like VH1 -->
            <!-- reaching 2.1M views on YouTube. She has also been cast in various films including "Dance For Me" on BET+ release on 2023. -->

        Luna Bae is more than just a model—she is a movement. Through her platform, she continues to break barriers, redefine beauty standards, and inspire others to embrace their individuality. Whether on the runway, in front of the camera, or behind the scenes, Luna’s influence is undeniable, and her journey is only just beginning.
        </h3>
    </div>

      <div class="sec">
        <div class="img" style="background-image: url(../../assets/lunabae_hands.jpeg);">
        </div>
        <img src="../../assets/lunabae_handss.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            <!-- Continue to follow this talent on her journey to breaking barriers in the entertainment industry. -->
        Follow her journey on socials
        </h3>
        <social-networks color="white" style="position: absolute; left:0; width: 100vw; bottom: 0;"></social-networks>
    </div>

</div>
<!-- <app-social></app-social> -->
